import { metrics, PageLoadMetric } from '@atlassian/browser-metrics';
import hasNoInjectedServerState from '../utilities/has-no-injected-server-state';

const cachedMetrics = new Map<string, PageLoadMetric>();

export const getPageLoadMetric = (pageId: string) => {
  // Convert to kebab case to comply with naming convention:
  // https://hello-atlassian-net.jira.btpn.skyfencenet.com/wiki/spaces/APD/pages/1435310934/RUNBOOK+Get+the+Experience+Event+Key#Naming-conventions

  const page = pageId
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();

  let metric = cachedMetrics.get(page);

  if (!metric) {
    metric = metrics.pageLoad({
      key: page,
      custom: {
        isServedByBifrost: hasNoInjectedServerState(),
      },
    });
    cachedMetrics.set(page, metric);
  }

  return metric;
};
