export type Env = 'local' | 'dev' | 'stg' | 'stg-fedm' | 'prod' | 'prod-fedm' | 'unknown';

export type EnvType = 'dev' | 'staging' | 'prod' | 'unknown';

export type EnvConfig = {
  recaptcha: {
    smsEnrollmentRecaptchaEnterpriseInvisibleKey: string;
    smsEnrollmentRecaptchaEnterpriseNewKey: string;
  };
};

function inferEnvFromHostname(hostname: string): Env {
  if (hostname === 'localhost') {
    return 'local';
  }

  if (hostname.endsWith('.dev.internal.atlassian.com') || hostname.endsWith('.dev.atlassian.com')) {
    return 'dev';
  }

  if (hostname.endsWith('.stg.internal.atlassian.com') || hostname.endsWith('.stg.atlassian.com')) {
    return 'stg';
  }

  if (hostname.endsWith('.stg.atlassian-us-gov-mod.com')) {
    return 'stg-fedm';
  }

  if (hostname.endsWith('.atlassian-us-gov-mod.com')) {
    return 'prod-fedm';
  }

  if (hostname.endsWith('.atlassian.com')) {
    return 'prod';
  }

  return 'unknown';
}

export const currentEnv: Env = inferEnvFromHostname(window.location.hostname);
export const getCurrentEnv = (): Env => inferEnvFromHostname(window.location.hostname);

const envTypes: Record<Env, EnvType> = {
  local: 'dev',
  dev: 'dev',
  stg: 'staging',
  'stg-fedm': 'staging',
  prod: 'prod',
  'prod-fedm': 'prod',
  unknown: 'unknown',
};
export const currentEnvType: EnvType = envTypes[currentEnv];

const idacOrigins: Record<Env, string> = {
  local: 'http://localhost:8080', // idproxy local
  dev: 'https://id-dev-internal-atlassian-com.jira.btpn.skyfencenet.com',
  stg: 'https://id-stg-internal-atlassian-com.jira.btpn.skyfencenet.com',
  'stg-fedm': 'https://id.stg.atlassian-us-gov-mod.com',
  prod: 'https://id-atlassian-com.jira.btpn.skyfencenet.com',
  'prod-fedm': 'https://id.atlassian-us-gov-mod.com',
  unknown: 'https://id-atlassian-com.jira.btpn.skyfencenet.com', // safe default
};

export const idacOrigin = idacOrigins[currentEnv];

export type Perimeter = 'commercial' | 'fedramp-moderate';

const perimeters: Record<Env, Perimeter> = {
  local: 'commercial',
  dev: 'commercial',
  stg: 'commercial',
  'stg-fedm': 'fedramp-moderate',
  prod: 'commercial',
  'prod-fedm': 'fedramp-moderate',
  unknown: 'commercial',
};

const config: Record<EnvType, EnvConfig> = {
  dev: {
    recaptcha: {
      smsEnrollmentRecaptchaEnterpriseInvisibleKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI', // no captcha site key
      smsEnrollmentRecaptchaEnterpriseNewKey: '6Ld_RrgpAAAAAC6NtADmI_b9uTwxyDieSQbL0A4O',
    },
  },
  staging: {
    recaptcha: {
      smsEnrollmentRecaptchaEnterpriseInvisibleKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI', // no captcha site key
      smsEnrollmentRecaptchaEnterpriseNewKey: '6Ld_RrgpAAAAAC6NtADmI_b9uTwxyDieSQbL0A4O',
    },
  },
  prod: {
    recaptcha: {
      smsEnrollmentRecaptchaEnterpriseInvisibleKey: '6LeLRz0kAAAAAPIWGnreLcE0BJaZ17REyxkFhM0E',
      smsEnrollmentRecaptchaEnterpriseNewKey: '6LcjUrgpAAAAANvg2hgPsYsHNO9F21kQVCQwUPoF',
    },
  },
  unknown: {
    recaptcha: {
      smsEnrollmentRecaptchaEnterpriseInvisibleKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI', // no captcha site key
      smsEnrollmentRecaptchaEnterpriseNewKey: '6Ld_RrgpAAAAAC6NtADmI_b9uTwxyDieSQbL0A4O',
    },
  },
};

export const currentPerimeter = perimeters[currentEnv];
export const getCurrentPerimeter = () => perimeters[currentEnv];
export const isFedM = () => getCurrentPerimeter() === 'fedramp-moderate';
export const getConfig = () => config[currentEnvType];
