import { currentEnv } from '../utilities/env';

export const statsigTargetApp = 'aid-frontend_web';

export const getStatsigApiKey = () => {
  // Provided by https://developer-atlassian-com.jira.btpn.skyfencenet.com/platform/frontend-feature-flags/resources/api-keys/
  switch (currentEnv) {
    case 'dev':
    case 'local':
      return 'f0f574a2-4bcc-4049-a7d3-6a08637916e0';
    case 'stg':
      return 'de816d56-15e9-402b-8d1b-09ab6175bb1c';
    case 'stg-fedm':
      return '487d67c3-d876-46d4-ac06-7a4b066e5b78';
    case 'prod-fedm':
      return 'e8a35d9c-f310-4842-9141-66e28cd0d395';
    case 'prod':
    default:
      return 'af2669c5-bf1e-48d6-b37d-a8514b7073d2';
  }
};

export const getBooleanDynamicConfigValueOrDefault = (dynamicConfigs, configKey) => {
  const configResult = dynamicConfigs.getBoolean(configKey);
  if ('value' in configResult) {
    return configResult.value;
  }
  return false;
};
